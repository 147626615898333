<template>
  <div class="hosted-pg">
    <!-- Payment Request Template List card starts here -->

    <vs-card class="mt-5">
      <div slot="header">
        <div class="flex justify-between items-center">
          <h3 class="text-2xl font-medium">Pages</h3>
        </div>

      </div>
      <div class="flex justify-end">
        <vs-button size="medium" v-round class="only-border-btn" @click="$router.push({ name: 'hosted-page-create' })">
          <plus-icon size="1.5x" class="button-icon"></plus-icon>New Page
        </vs-button>
      </div>

      <vs-table :data="paymentRequestTemplates" class="mt-5">
        <template slot="thead">
          <vs-th> Name </vs-th>
          <vs-th> URL</vs-th>
          <vs-th></vs-th>
          <vs-th>Account</vs-th>
          <vs-th> Status</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.templateName">{{ tr.templateName }}</vs-td>
            <vs-td :data="tr.link">{{ tr.status !== 'Archived' ? tr.link : '' }}</vs-td>
            <vs-td>
              <vs-tooltip text="Copy to clipboard" class="pointer" v-if=" tr.status !== 'Archived'">
                <vs-icon icon="content_copy" size="medium" @click="copyPortalLinkToClipboard(tr.link)" class="clipIcon color-grey"/>
                <span>&nbsp;</span>
              </vs-tooltip>
            </vs-td>
            <vs-td :data="tr">
              {{ tr.bank ? tr.bank.displayName || tr.bank.accountName : '' }}
            </vs-td>
            <vs-td :data="tr.status">
              <span :class="changeStatusColor(tr)" v-if="statusText(tr)!==''">{{statusText(tr)}}</span>
            </vs-td>
            <vs-td :data="tr" v-if="tr.status !== 'Archived'">
              <a class="pl-5 pr-5" @click="editTemplate(tr._id)">Edit</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>
    <delete-popup
      :popup-active="popupDelActive"
      :warningContent="warningContent"
      :deleteWhat="deleteWhat"
      :buttonText="buttonText"
      @handleDeleteClick="delActions"
    ></delete-popup>

    <!-- Payment Request Template List card ends here -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DeletePopup from "@/views/components/DeletePopup";
import { PlusIcon } from "vue-feather-icons";

export default {
  components: { DeletePopup, PlusIcon },
  data() {
    return {
      warningContent: `There are pending requests linked to this template. By deleting
              this template you will also cancel any pending payment requests
              sent from this template.`,
      deleteWhat: `hosted payment page`,
      buttonText: `Delete Hosted Payment Page`,
      paymentRequestTemplates: [],
      popupDelActive: false,
      deleteRTid: "",
      clientUrl: process.env.VUE_APP_ROOT_API,
       merchantGroups: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
  },
  mounted() {
    this.merchantId = JSON.parse(localStorage.user).userType == "admin" ? JSON.parse(localStorage.user).merchantId : JSON.parse(localStorage.user).partner.merchantId;
    this.getRequestTemplateByMerchantId();
    this.getMerchantGroups(this.partnerId);
  },
  methods: {
    ...mapActions("merchantGlobalSetting", [ "fetchSettingsByMerchantId" ]),
    ...mapActions("paymentRequestTemplate", ["fetchRequestTemplateByMerchantId", "updateRequestTemplateById"]),
    ...mapActions("merchantGroup", ["fetchMerchantGroupByMerchantId"]),

     async getMerchantGroups(partnerId) {
      await this.fetchMerchantGroupByMerchantId(partnerId)
        .then((res) => {
          this.merchantGroups = res.data.data.docs;
        })
        .catch((err) => {});
    },

    async deleteRequestTemplate() {
      let obj = { id: this.deleteRTid, payload: { merchantId: this.partnerId, deleted: true } };
      this.updateRequestTemplateById(obj).then(
        (res) => {
          this.getRequestTemplateByMerchantId();
        }
      );
    },

    changeStatusColor(tr) {
      let bgClass = "";
      let status;
      if (tr.status === "Archived") {
        status = "Archived"
      } else {
        status = tr.assignedToGroup == 0 ? "Not assigned" : "Assigned";
      }

      if (status == "Archived") {
        bgClass = "badge archived";
      } else if (status == "Assigned") {
        bgClass = "badge success";
      } else {
        bgClass = "badge warning";
      }

      return bgClass;
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.deleteRequestTemplate(this.delIndex);
          break;
        case "cancel":
          this.popupDelActive = false;
          this.delIndex = "";
          break;
        default:
          this.popupDelActive = false;
          this.delIndex = "";
      }
    },

    async getRequestTemplateByMerchantId() {
      let data = {
        merchantId: this.partnerId,
        type: "hostedpage"
      }
      await this.fetchRequestTemplateByMerchantId(data).then(
        (res) => {
          this.paymentRequestTemplates = res.data.data;
          this.paymentRequestTemplates = this.paymentRequestTemplates.map(
            (element) => ({
              ...element,
              link: this.clientUrl+'payment-request/merchant/'+element._id
            })
          );
      
        }
      );
    },
    editTemplate(id) {
      this.$router.push({
        name: "hosted-page-edit",
        params: { id: id },
      });
    },
    statusText(tr) {
      if (tr.status  === 'Archived') {
        return "Archived"
      }

      if (this.merchantGroups.length > 0 ) {
        if (tr.assignedToGroup == 0) {
          return "Not assigned";
        } else {
          return "Assigned";
        }
      }
      return '';
    },
    handleDelete(id) {
      this.deleteRTid = id;
      this.popupDelActive = true;
    },
    copyPortalLinkToClipboard(link) {
      navigator.clipboard.writeText(link).then(() => {
        this.$vs.notify({
          title: "Copy to Clipboard",
          text: "Copied text to clipboard",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
      });
    },
  },
};
</script>